import axios from "axios";
import authHeader from "./auth-header";

const BASE_URL = "http://localhost:8080";
const AUTH_URL = BASE_URL + "/api/roles/";
const DASHBOARD_URL = BASE_URL + "/api/dashboard/";

const getPublicContent = () => {
    return axios.get(AUTH_URL + "all");
};

const getUserBoard = () => {
    return axios.get(AUTH_URL + "user", {headers: authHeader()});
};

const getModeratorBoard = () => {
    return axios.get(AUTH_URL + "mod", {headers: authHeader()});
};

const getAdminBoard = () => {
    return axios.get(AUTH_URL + "admin", {headers: authHeader()});
};

const getCurrentMonthExpenses = () => {
    return axios.get(DASHBOARD_URL + "current-month", {headers: authHeader()});
};

export default {
    getPublicContent,
    getUserBoard,
    getModeratorBoard,
    getAdminBoard,
    getCurrentMonthExpenses
};