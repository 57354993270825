import React, { useEffect, useState } from "react";
import Table from 'react-bootstrap/Table';
import Pagination from 'react-bootstrap/Pagination';
import Button from 'react-bootstrap/Button';

const TableComponent = () => {
    const [expenses, setExpenses] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [searchDescription, setSearchDescription] = useState("");
    const [searchCard, setSearchCard] = useState("");
    const [searchCardHolder, setSearchCardHolder] = useState("");
    const expensesPerPage = 10;

    useEffect(() => {
        // Fetch expenses data from API
        const fetchExpenses = async () => {
            try {
                const response = await fetch("http://localhost:8080/api/dashboard/current-month");
                const jsonData = await response.json();
                setExpenses(jsonData);
            } catch (error) {
                console.error("Failed to fetch expenses:", error);
            }
        };
        fetchExpenses();
    }, []);

    const indexOfLastExpense = currentPage * expensesPerPage;
    const indexOfFirstExpense = indexOfLastExpense - expensesPerPage;

    const filteredExpenses = expenses.filter(expense => {
        const descriptionMatch = expense.description.toLowerCase().includes(searchDescription.toLowerCase());
        const cardMatch = expense.card.toLowerCase().includes(searchCard.toLowerCase());
        const cardHolderMatch = expense.fullName.toLowerCase().includes(searchCardHolder.toLowerCase());
        return descriptionMatch && cardMatch && cardHolderMatch;
    });

    const currentExpenses = filteredExpenses.slice(indexOfFirstExpense, indexOfLastExpense);
    const totalPages = Math.ceil(filteredExpenses.length / expensesPerPage);

    const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber);
    };

    const handleSearchClick = () => {
        setCurrentPage(1); // Reset to the first page when performing a search
    };

    return (
        <div>
            <div className="search-form">
                <input
                    type="text"
                    placeholder="Search by description"
                    value={searchDescription}
                    onChange={(e) => setSearchDescription(e.target.value)}
                />
                <input
                    type="text"
                    placeholder="Search by card"
                    value={searchCard}
                    onChange={(e) => setSearchCard(e.target.value)}
                />
                <input
                    type="text"
                    placeholder="Search by cardholder"
                    value={searchCardHolder}
                    onChange={(e) => setSearchCardHolder(e.target.value)}
                />
                <Button variant="primary" onClick={handleSearchClick}>Search</Button>
            </div>
            <Table striped bordered hover>
                <thead>
                <tr>
                    <th>No.</th>
                    <th>Card</th>
                    <th>Transaction Date</th>
                    <th>Posted Date</th>
                    <th>Amount</th>
                    <th>Description</th>
                    <th>CardHolder</th>
                </tr>
                </thead>
                <tbody>
                {currentExpenses.map((expense, indexWithinPage) => (
                    <tr key={expense.id}>
                        <td>{(currentPage - 1) * expensesPerPage + indexWithinPage + 1}</td>
                        <td>{expense.card}</td>
                        <td>{expense.transactionDate}</td>
                        <td>{expense.postedDate}</td>
                        <td>{toCurrency(expense.amount)}</td>
                        <td>{expense.description}</td>
                        <td>{expense.fullName}</td>
                    </tr>
                ))}
                </tbody>
            </Table>
            <Pagination>
                {Array.from({ length: totalPages }).map((_, index) => (
                    <Pagination.Item
                        key={index + 1}
                        active={index + 1 === currentPage}
                        onClick={() => handlePageChange(index + 1)}
                    >
                        {index + 1}
                    </Pagination.Item>
                ))}
            </Pagination>
        </div>
    );
};

function toCurrency(amount) {
    return new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'CAD'
    }).format(amount);
}

export default TableComponent;